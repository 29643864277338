<script setup lang="ts"></script>

<template>
  <div class="the-page">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.the-page {
  padding-bottom: 32px;
}
</style>
